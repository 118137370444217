import { format } from "date-fns";

const StatusItem = ({created, status, officeName, name, additionalClass}) => {
  var date;
  try {
    date = format(created.toDate(), "MMM dd, yyyy ~ h:mma");
  } catch (error) {
    date = format(created, "MMM dd, yyyy ~ h:mma")
  }

    return (
    <div className={`tracking-item ${additionalClass}`}>
            <h3>{officeName}</h3>
            <span>{status}</span>

            <p>
              {name}
              <br/>
              {date}
            </p>
        </div>
    )
}

export default StatusItem;