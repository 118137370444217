import leave_sick from '../../assets/leave_sick.png'
import leave_others from '../../assets/leave_others.png'
import leave_sick_listless from '../../assets/leave_sick_listless.png'
import leave_others_listless from '../../assets/leave_others_listless.png'
import bills from '../../assets/bills.png'
import fuel_issuance from '../../assets/fuel_issuance.png'
import fuel_payment from '../../assets/fuel_payment.png'
import payroll_atm from '../../assets/payroll_atm.png'
import payroll_check from '../../assets/payroll_check.png'
import payroll_cash_advance from '../../assets/payroll_cash_advance.png'
import payroll_pto from '../../assets/payroll_pto.png'
import payment_supplier from '../../assets/payment_supplier.png'
import travel_seminars from '../../assets/travel_seminars.png'
import travel_regular from '../../assets/travel_regular.png'
import travel_seminars_listless from '../../assets/travel_seminars_listless.png'
import travel_regular_listless from '../../assets/travel_regular_listless.png'
import honorarium_cash_advance from '../../assets/honorarium_cash_advance.png'
import honorarium_check from '../../assets/honorarium_check.png'
import petty_cash from '../../assets/petty_cash.png'
import procurement_bidding from '../../assets/procurement_bidding.png'
import procurement_shopping from '../../assets/procurement_shopping.png'
import voucher_cash_advance from '../../assets/voucher_cash_advance.png'
import voucher_reimbursement from '../../assets/voucher_reimbursement.png'
import infra_1m from '../../assets/infra_1m.png'
import infra_5m from '../../assets/infra_5m.png'
import financial_assistance from '../../assets/financial_assistance.png'
import payment_maip from '../../assets/payment_maip.png'

const RoutingSlip = ({type, sub, participants}) => {

    var img;
    switch (type) {
        case "Payment for MAIP Program Claims":
            img = payment_maip;
            break;
        case "Financial Assistance":
            img = financial_assistance;
            break;
        case "Leave":
            if (participants) {
                if (sub === "Sick Leave") {
                    img = leave_sick_listless;
                } else {
                    img = leave_others_listless;
                }
            } else {
                if (sub === "Sick Leave") {
                    img = leave_sick;
                } else {
                    img = leave_others;
                }
            }
            break;
        case "Bills":
            img = bills;
            break;
        case "Payroll":
        case "Payroll/Other Employee Benefits":
            if (sub === "ATM") {
                img = payroll_atm;
            } else if (sub === "Check") {
                img = payroll_check;
            } else {
                img = payroll_cash_advance;
            }
            break;
        case "Cash Advance":
            img = payroll_pto;
            break
        case "Payment to Suppliers":
            if (sub === "Fuel/Oil") {
                img = fuel_payment;
            } else {
                img = payment_supplier;
            }
            break;
        case "Travel Order":
            if (participants) {
                if (sub === "Regular Travel") {
                    img = travel_regular_listless;
                } else {
                    img = travel_seminars_listless;
                }
            } else {
                if (sub === "Regular Travel") {
                    img = travel_regular;
                } else {
                    img = travel_seminars;
                }
            }
            break;
        case "Honorarium":
            if (sub === "Check") {
                img = honorarium_check;
            } else {
                img = honorarium_cash_advance;
            }
            break;
        case "Petty Cash":
            img = petty_cash;
            break;
        case "Driver's Trip Ticket/Requisition and Issuance Slip":
            img = fuel_issuance;
            break;
        case "Purchase Request/Service Request":
        case "Purchase Request":
            if (sub === "Competitive Bidding") {
                img = procurement_bidding;
            } else {
                img = procurement_shopping;
            }
            break;
        case "Travel Expense Voucher":
            if (sub === "Cash Advance") {
                img = voucher_cash_advance;
            } else {
                img = voucher_reimbursement;
            }
            break;
        case "Program of Works":
            if (sub === "Below 1M") {
                img = infra_1m;
            } else {
                img = infra_5m;
            }
            break;
    }
    return (
        <img className="routing-img"  src={img}/>
    )
}

export default RoutingSlip;