import './remarks-item.scss'
import { format } from "date-fns";

const RemarksItem = ({user, doc, officeName, name, onDeleteHandler, additionalClass}) => {
  const {created, remarks, uid} = doc;
  var date;

  try {
    date = format(created.toDate(), "MMM dd, yyyy ~ h:mma");
  } catch (error) {
    date = format(created, "MMM dd, yyyy ~ h:mma")
  }

    return (
    <div className={`remarks-item ${additionalClass ? additionalClass : ""}`}>
            <p><span className='name'>{name}</span> <span className='office'>{officeName}</span> <span className="date">{date}</span></p>
            <p>{remarks}</p>
            <p className='delete-container'>{user === uid ? <span className="delete" onClick={() => onDeleteHandler(doc.id)}>Delete</span> : <span className="delete hidden">-</span>}</p>
        </div>
    )
}

export default RemarksItem;