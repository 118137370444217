import { createContext, useState, useEffect } from "react";
import { getOffices } from "../utils/firebase";

export const ProductsContext = createContext({
    offices: [],
    setOffices: () => null
});

export const ProductsProvider = ({ children }) => {
    const [offices, setOffices] = useState([]);
    const value = {offices, setOffices};

    useEffect(() => {
        const getResponse = async () => {
            setOffices(await getOffices());
        }
        getResponse();
    }, []);

    return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>
} 