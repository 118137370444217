import { createContext, useState, useEffect } from "react";
import { onAuthStateChangedListener, createUserDocument } from "../utils/firebase";

export const UserContext = createContext({
    currentUser: null,
    setCurrentUser: () => null,
    code: null,
    setCode: () => {},
});

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [code, setCode] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener(async (user) => {
            if (user) {
                await createUserDocument(user);
            }
            setCurrentUser(user);
        });

        return unsubscribe;
    }, []);

    const value = {currentUser, setCurrentUser, code, setCode};

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
} 