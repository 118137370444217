import { createContext, useState, useEffect } from "react";
import { getUsers } from "../utils/firebase";

export const UsersContext = createContext({
    users: [],
    setUsers: () => null
});

export const UsersProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const value = {users, setUsers};

    useEffect(() => {
        const getResponse = async () => {
            setUsers(await getUsers());
        }
        getResponse();
    }, []);

    return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
} 