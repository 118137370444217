import './form-textarea.scss'

const FormTextArea = ({ label, value, ...otherProps }) => {
    return (
        <div className='group'>
        <textarea className='form-textarea' {...otherProps} value={value}></textarea>
            {label && (
                <label className={`${value.length ? 'shrink' : ''} form-input-label`}>{label}</label>
            )}
        </div>
    );
}

export default FormTextArea;