import './two-factor.scss'
import { Fragment, useContext, useState } from "react";
import { UserContext } from "../../context/user-context";
import FormInput from "../../components/form-input/form-input";
import Button from "../../components/button/button";
import { Link, useNavigate } from "react-router-dom";
import seal from '../../assets/seal.png'
import { signOutUser } from "../../utils/firebase";

const TwoFactor = () => {
    const { code, setCode } = useContext(UserContext);
    const [text, setText] = useState("");
    const navigate = useNavigate();

    const signOutHandler = () => {
        setCode(null);
        signOutUser();
        navigate('/');
    }

    window.onbeforeunload = function() {
        signOutHandler();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (code !== text) {
            alert("Incorrect code");
            return;
        }

        setCode(null);
    }

    return (
        <Fragment>
            <div className="navigation-container">
                <div className="navigation">
                    <Link className="logo-container" to='/'>
                        <img alt="logo" className='logo' src={seal}/>
                        <h1>PGCN Document Tracking System</h1>
                    </Link>
                    <div className="nav-links-container">
                        <span className="nav-link" onClick={signOutHandler}>Sign Out</span>
                    </div>
                </div>
            </div>

            <div className="authorize-container">
            <h2>Two-factor Authentication</h2>
            <p>To secure and protect the use of your account, we need to verify that it's you. Verify the code we've sent to your email address.</p>
            <form onSubmit={handleSubmit}>
                <FormInput type="text" required name="code" value={text} onChange={(e) => {setText(e.target.value)}} label="Code"/> 

                <div className='buttons-container'>
                <   Button type="submit">Verify</Button>
                </div>
            </form>
            </div>
        </Fragment>
    )
}

export default TwoFactor;